<template>
    <div>
        <v-card class="rounded-lg" elevation="2">
            <v-tabs v-model="tab" fixed-tabs background-color="#50c78f" dark>
                <v-tabs-slider color="#b1e3cc"></v-tabs-slider>
                <v-tab>BUSCA ATIVA</v-tab>
                <!-- <v-tab :disabled="disableSerology">SOROLOGIA</v-tab> -->

                <v-tab-item>
                    <v-card-text>
                        <v-form @submit.prevent="activeSearchForm" ref="form" :disabled="disabledDonorForm">
                            <v-container>
                                <p style="font-weight:bold"> Possível Doador:</p>
                                <v-row>
                                    <v-col class="d-flex" cols="12" sm="4">
                                        <v-text-field 
                                        color="#256936" 
                                        :rules="formRules" 
                                        label="Nome Completo"
                                        outlined
                                        v-model="activeSearch.name" 
                                        dense></v-text-field>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="CPF"
                                        outlined
                                        v-model="activeSearch.cpf" 
                                        maxlength="14" 
                                        v-mask="'###.###.###-##'"
                                        dense></v-text-field>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-select 
                                        :items="sex"
                                        :rules="formRules"
                                        color="#086318" 
                                        v-model="activeSearch.sex_id"
                                        item-value="id"
                                        item-text="name"  
                                        dense 
                                        outlined 
                                        label="Gênero">
                                        </v-select>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="RG"
                                        outlined
                                        v-model="activeSearch.rg" 
                                        maxlength="13" 
                                        v-mask="'##.###.###-##'"
                                        dense></v-text-field>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#086318" 
                                        :rules="formRules" 
                                        label="Data de Nascimento"
                                        type="date" 
                                        outlined 
                                        v-model="activeSearch.birth_date"
                                        dense></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="d-flex" cols="12" sm="4">
                                        <v-text-field 
                                        color="#256936" 
                                        :rules="formRules" 
                                        label="Diagnóstico Inicial"
                                        outlined
                                        v-model="activeSearch.initial_diagnosis" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="3">
                                        <v-text-field 
                                        color="#256936" 
                                        :rules="formRules" 
                                        label="Número de Prontuário"
                                        type="number"
                                        outlined
                                        v-model="activeSearch.medical_records" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="3">
                                        <v-text-field 
                                        color="#256936" 
                                        :rules="formRules" 
                                        label="Localização"
                                        outlined
                                        v-model="activeSearch.location" 
                                        dense></v-text-field>
                                    </v-col>
                                
                                    <v-col class="d-flex" cols="12" sm="2" v-if="disabledDonorForm == false">
                                        <v-btn class="rounded-lg" elevation="0" color="#50c78f" @click="addDonorandScale()">
                                        <span class="textBtn">Salvar</span>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>

                        <v-form @submit.prevent="activeSearchForm" ref="form" :disabled="disableScaleForm">
                            <v-container>
                                <p class="space-personalized"></p>
                                <p style="font-weight:bold">Escala de Glasgow:</p>
                                <v-row>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="Escala"
                                        outlined
                                        v-model="glasgowScale.description" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="5">
                                        <v-text-field 
                                        color="#256936" 
                                        label="Evolução"
                                        outlined
                                        v-model="glasgowScale.evolution" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2" v-if="disableButtonScaleForm == false">
                                        <v-tooltip right color="#50c78f">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn class="mx-1"  small fab dark color="#50c78f" v-bind="attrs" v-on="on" @click="addGlasgowScale()">
                                                    <v-icon dark>mdi-plus</v-icon>
                                                </v-btn>
                                            </template>
                                        <span style="color:white">Inserir escala</span>
                                        </v-tooltip>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2" v-if="enableEditButton == true">
                                        <v-tooltip right color="#236947">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn class="mx-1"  small fab dark color="#236947" v-bind="attrs" v-on="on" @click="editScale()">
                                                    <v-icon dark>mdi-check</v-icon>
                                                </v-btn>
                                            </template>
                                        <span style="color:white">Salvar alteração</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                                <br>
                                <template>
                                    <v-data-table
                                        :headers="headers"
                                        :items="glasgow"
                                        :items-per-page="10"
                                        class="elevation-1"
                                    >
                                        <template v-slot:item.actions="{ item }" :resource="item">
                                            <v-tooltip bottom color="#50c78f">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon 
                                                        size="20"
                                                        class="icon"
                                                        color="#50c78f" 
                                                        v-on="on"
                                                        v-bind="attrs"
                                                        align-item="center"
                                                        @click="editGlasgowScale(item)">
                                                        mdi-pen
                                                    </v-icon>
                                                </template>
                                             <span style="color:white">Editar</span>
                                            </v-tooltip>
                                            <v-tooltip bottom color="red">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon 
                                                        size="20"
                                                        class="icon"
                                                        color="red" 
                                                        v-on="on"
                                                        v-bind="attrs"
                                                        align-item="center"
                                                        @click="deleteGlasgowScale(item)">
                                                        mdi-trash-can-outline
                                                    </v-icon>
                                                </template>
                                             <span style="color:white">Excluir</span>
                                            </v-tooltip>
                                        </template>
                                    </v-data-table>
                                </template>

                                <v-dialog persistent width="auto" v-model="dialogDeath">
                                    <v-card class="deathDialog">
                                        <v-card-title class="text-h5">
                                            Paciente: {{ activeSearch.name }}
                                        </v-card-title>
                                        <h4>{{ activeSearch.name }} evoluiu para morte encefálica?</h4>
                                            <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                color="#ed695a"
                                                variant="text"
                                                value="Não"
                                                @click="recusePatient()"
                                            >
                                                Não
                                            </v-btn>
                                            <v-btn
                                                color="#8fdb8f"
                                                variant="text"
                                                value="Sim"
                                                @click="dialogDonor = true; dialogDeath = false"
                                            >
                                                Sim
                                            </v-btn>
                                            </v-card-actions>
                                    </v-card>
                                </v-dialog>

                                <!-- alguém precisa abrir ele -->
                                <v-dialog persistent width="auto" v-model="dialogDonor">
                                    <v-card class="deathDialog">
                                        <v-card-title class="text-h5">
                                            <v-icon dark class="donorIcon">mdi-account-plus</v-icon>
                                            Cadastrar como doador? 
                                        </v-card-title>
                                        <h4>Enviar dados de {{ activeSearch.name }} para o cadastro de doador?</h4>
                                            <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                color="#ed695a"
                                                variant="text"
                                                value="Não"
                                                @click="$router.push('/busca-ativa')"
                                            >
                                                Não
                                            </v-btn>
                                            <v-btn
                                                color="#8fdb8f"
                                                variant="text"
                                                value="Sim"
                                                @click="sendToDonor()"
                                            >
                                                Sim
                                            </v-btn>
                                            </v-card-actions>
                                    </v-card>
                                </v-dialog>

                                <p class="space-personalized"></p>

                                <div class="buttons">
                                    <v-row>
                                        <v-btn class="rounded-lg" elevation="0" color="#50c78f" v-if="disabledDonorForm == true" @click="$router.push('/busca-ativa')">
                                            <span class="textBtn">Salvar</span>
                                        </v-btn>
                                        <p class="space-personalized"></p>
                                        <v-btn class="rounded-lg" elevation="0" color="#40a374" v-if="disabledDonorForm == true" @click="dialogDeath = true">
                                            <span class="textBtn">Concluir Cadastro</span>
                                        </v-btn>
                                        <!-- <p class="space-personalized"></p>
                                        <v-btn class="rounded-lg" elevation="0" color="#eda815" v-if="disabledDonorForm == true" @click="activeSerology()">
                                            <span class="textBtn">Adicionar Sorologia</span>
                                        </v-btn> -->
                                        <p class="space-personalized"></p>
                                        <v-btn class="rounded-lg" elevation="0" color="grey"  @click="$router.push('/busca-ativa')">
                                            <span class="textBtn">Sair</span>
                                        </v-btn>
                                    </v-row>
                                </div>
                            </v-container>
                        </v-form>
                    </v-card-text>
                </v-tab-item>

                <v-tab-item>
                    <Sorologia :glasgowId="donorIdSaved" />
                </v-tab-item>
            </v-tabs>
        </v-card>

        <ModalBuscaAtiva :activeModal="openModal" :donorData="donorForm" @fechar="openModal = false"/>

        <v-snackbar absolute background-color="white" color="#236947" v-model="snackbar">
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                Fechar
                </v-btn>
            </template>
        </v-snackbar>

        <v-snackbar absolute background-color="white" color="#9e212e" v-model="snackbarLimit">
          {{ message }}
          <template v-slot:action="{ attrs }">
              <v-btn color="white" text v-bind="attrs" @click="snackbarLimit = false">
              Fechar
              </v-btn>
          </template>
      </v-snackbar>
    </div>
</template>
  
<script>
import axios from "@/axios";
import Sorologia from "../pacientes/doador/sorologia/Sorologia-comp.vue";
import ModalBuscaAtiva from "./ModalBuscaAtiva-comp.vue";

export default {
    data() {
        return {
            headers: [
            {
              text: "Escala de Glasgow",
              value: "description",
              align: "center",
            },
            {
              text: "Responsável pela análise",
              value: "users.name",
              align: "center",
            },
            {
              text: "Evolução",
              value: "evolution",
              align: "center",
            },
            {
              text: "Data",
              value: "created_at",
              align: "center",
            },
            {
              text: "Ações",
              value: "actions",
              align: "center",
            }
            ],
            openModal: false,
            dialog: false,
            dialogDonor: false,
            dialogDeath: false,
            activeEvolution: false,
            disabledDonorForm: false,
            disableScaleForm: true,
            disableButtonScaleForm: true,
            activateButtons: false,
            enableEditButton: false,
            displayError: {
                display: false,
                message: [],
            },
            donorForm:{},
            activeSearch: {
                unity_id: "",
                hospital_id: "",
                donor_status_id: 1,
                is_donor: null
            },
            glasgowScale: {
                donor_id: "",
                user_id: "",
            },
            evolutionForm: {
                description: "",
                donor_id: "",
                glasgow_scale_id: ""
            },
            evolutionID: "",
            itemScaleID: "",
            glasgow: [],
            glasgowId: "",
            donor_id: "",
            donorIdSaved: "",
            newScales: false,
            newDonor: true,
            loading: true,
            options: {},
            donor: [],
            status_serology: [],
            message: "",
            snackbar: false,
            snackbarLimit: false,
            messageError: "",
            unity: "",
            description: "",
            rg:"",
            sex: [],
            tab: null,
            disableSerology: true,
            formRules: [
             (v) => !!v || "Este campo é obrigatório",
            ],
        };
    },

    components: {
      Sorologia,
      ModalBuscaAtiva
    },

    created() {
        axios.get(`/donor`).then((response) => { this.donor = response.data.data;});
        axios.get(`/serology_status`).then((response) => {this.status_serology = response.data.data;});
        axios.get("/sex").then((response) => {this.sex = response.data.data;});
    },

    watch: {
        options: {
            handler() {
                this.indoDataTable();
            },
        },
        deep: true,
    },

    methods: {
        indoDataTable() {
            this.loading = true;
        },

        
        indoGlasgowTable() {
            axios.get(`/glasgow_scale/donor/${this.donorIdSaved}`).then((response) => {
                this.glasgow = response.data;
            });
        },

        addDonorandScale(){
            if(this.$refs.form.validate() ) {
                this.activeSearch.unity_id = this.$store.state.unity_id
                this.activeSearch.hospital_id = this.$store.state.unity_id
                this.activeSearch.kind_of_donor_id = 1
                const donor = (this.activeSearch);
                axios
                    .post(`/donor/salvarDoador`, donor)
                        .then((response) => {
                            this.donorIdSaved = response.data.id.id
                            this.donorData = response.data.data
                            this.disabledDonorForm = true;
                            this.disableScaleForm = false;
                            this.disableButtonScaleForm = false;
                            this.newDonor = false;
                            this.newScales = true;
                            this.indoGlasgowTable(this.donorIdSaved);
                        })
                    .catch((err) => {
                    err;
                    var error = "";
                    Object.values(this.$store.state.errorMessage).map((value) => {
                        error = value;
                    });
                        this.displayError.message = error.toString();
                        this.displayError.display = true;
                    setTimeout(() => {
                        this.displayError.display = false;
                    }, 3000);
                })
            }
        },

        addGlasgowScale(){
            this.glasgowScale.donor_id = this.donorIdSaved
            this.glasgowScale.user_id = this.$store.state.authUser.id
            const glasgowScale = this.glasgowScale
            axios
                .post(`/glasgow_scale`, glasgowScale)
                    .then((response) => {
                        this.message = response.data.message;
                        this.glasgowId = response.data.id.id
                        this.snackbar = true;
                        this.dialog = false;
                        this.glasgowScale.description = ""
                        this.glasgowScale.evolution = ""
                        this.activateButtons = true
                        this.disableScaleForm = false
                        this.disableButtonScaleForm = false
                        this.indoGlasgowTable(this.donorIdSaved);
                    })
                .catch((err) => {
                    err;
                    var error = "";
                    Object.values(this.$store.state.errorMessage).map((value) => {
                        error = value;
                    });
                        this.displayError.message = error.toString();
                        this.displayError.display = true;
                    setTimeout(() => {
                        this.displayError.display = false;
                    }, 3000);
            })
        },

        recusePatient(){
            this.activeSearch.is_donor = "não"
            const donor = this.activeSearch
            axios
            .patch(`/donor/${this.donorIdSaved}`, donor)
                .then(() => {
                    setTimeout(() => {
                        this.$router.push({ path: '/busca-ativa'});
                    }, "1000");
                }).catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
                error = value;
            });
                this.displayError.message = error.toString();
                this.displayError.display = true;
            setTimeout(() => {
                this.displayError.display = false;
            }, 3000);
        })
        },

        sendToDonor(){
            this.activeSearch.kind_of_donor_id = 2
            this.activeSearch.donor_status_id = 2

            const donor = this.activeSearch
            axios
            .patch(`/donor/${this.donorIdSaved}`, donor)
                .then(() => {
                    this.activeSearch.id = this.donorIdSaved
                    this.$router.push({
                        path: '/cadastrar-doador', 
                        query: {item:this.activeSearch}
                    });
                }).catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
                error = value;
            });
                this.displayError.message = error.toString();
                this.displayError.display = true;
            setTimeout(() => {
                this.displayError.display = false;
            }, 3000);
        })
        },

        editGlasgowScale(item){
            this.disableButtonScaleForm = true
            this.glasgowScale.evolution = item.evolution
            this.glasgowScale.description = item.description
            this.enableEditButton = true
            this.itemScaleID = item.id
        },

        editScale(){
            this.glasgowScale.donor_id = this.donorIdSaved
            this.glasgowScale.user_id = this.$store.state.authUser.id
            const scale = this.glasgowScale
            let id = this.itemScaleID
            axios
            .put(`/glasgow_scale/${id}`, scale)
            .then((response) => {
                this.indoGlasgowTable(this.donorIdSaved);
                this.enableEditButton = false
                this.glasgowScale.description = ""
                this.glasgowScale.evolution = ""
                this.disableButtonScaleForm = false
                this.message = response.data.message;
                if(this.message === "Você excedeu o limite de 15 minutos para edição."){
                    this.snackbarLimit = true
                    this.dialog = false;
                }else{
                    this.snackbar = true;
                    this.dialog = false;
                }
            })
            .catch((err) => {
                err;
                var error = "";
                Object.values(this.$store.state.errorMessage).map((value) => {
                error = value;
                });

                this.displayError.message = error.toString();
                this.displayError.display = true;
                setTimeout(() => {
                this.displayError.display = false;
                }, 3000);
            });
        },

        activeSerology(){
            this.disableSerology = false;
            localStorage.setItem("activeSearchDonorId", this.donorIdSaved);
            this.tab = 1
        },

        deleteGlasgowScale(item){
            axios.delete(`/glasgow_scale/${item.id}`).then((response) => {
                this.loading = true;
                this.message = response.data.message;
                this.snackbar = true;
                this.indoGlasgowTable();
                this.disableScaleForm = false;
                this.disableButtonScaleForm = false;
            })
        },

    },

    mounted() {
        this.indoDataTable();
    },

};
</script>
  
<style scoped>

.deathDialog>h4{
    color: #413f3f;
    padding: 20px;
}
.text-h5{
    background-color: #50c78f;
    color: white;
    text-shadow: 1px 2px 2px #309171
}
.donorIcon{
    padding:  8px 8px 8px 0;
}
.header-table {
    display: flex;
    margin-bottom: 20px;
}

p{
    font-size: 16px;
    /* font-weight: bold; */
}
.d-flex {
    padding: 0 12px;
}

.space {
    width: 5px;
}

.space-personalized {
    padding: 4px;
}

.spacediferent {
    width: 7px;
}

.v-btn:not(.v-btn--round).v-size--default {
    height: 40px !important;
}

.textBtn {
    font-family: "Poppins";
    color: #ffffff;
}

.create-user-dialog {
    padding: 20px;
}

.card {
    padding: 35px;
}

.banner {
  background: #b3ffab;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right,
      #0ca673,
      #1abc9c);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right,
      #0cb087,
      #66f9b9);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.textBtn{
  color:#ffffff;
}
.banner>h3 {
  color: #ffffff;
  padding: 18px 0 18px 0;
}
.modalIcon{
  padding: 12px;
}

 .cardFooter {
    padding: 0 !important;
}
</style>
  