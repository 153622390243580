<template>
    <div>
        <v-dialog
        v-model="activeModal"
        activator="parent"
        persistent
        width="auto"
      >
        <v-card>
          <div class="form">
            <v-row>
            <v-col class="d-flex" cols="12" sm="3">
              <v-radio-group v-model="deathData.miotic_pupils">
                <p>Pupilas Mióticas:</p>

                <v-radio label="Sim" 
                value="1" 
                color="#086318"
                ></v-radio>

                <v-radio label="Não" 
                value="2" 
                color="#086318"
                ></v-radio>
              </v-radio-group>
            </v-col>

            <v-col class="d-flex" cols="12" sm="3">
              <v-radio-group v-model="deathData.mydriatics_pupils">
                <p>Pupilas Midriáticas:</p>

                <v-radio label="Sim" 
                value="1" 
                color="#086318"
                ></v-radio>

                <v-radio label="Não" 
                value="2" 
                color="#086318"
                ></v-radio>
              </v-radio-group>
            </v-col>

            <v-col class="d-flex" cols="12" sm="3">
              <v-radio-group v-model="deathData.isochoric_pupils">
                <p>Pupilas Isocóricas:</p>

                <v-radio label="Sim" 
                value="1" 
                color="#086318"
                ></v-radio>

                <v-radio label="Não" 
                value="2" 
                color="#086318"
                ></v-radio>
              </v-radio-group>
            </v-col>
            
            <v-col class="d-flex" cols="12" sm="3">
              <v-radio-group v-model="deathData.anisochoric_pupils">
                <p>Pupilas Anisocóricas:</p>

                <v-radio label="Sim" 
                value="1" 
                color="#086318"
                ></v-radio>

                <v-radio label="Não" 
                value="2" 
                color="#086318"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex" cols="12" sm="3">
              <v-radio-group v-model="deathData.eyelid_corneal_reflex">
                <p>Reflexo Corneo Palpebral:</p>

                <v-radio label="Sim" 
                value="1" 
                color="#086318"
                ></v-radio>

                <v-radio label="Não" 
                value="2" 
                color="#086318"
                ></v-radio>
              </v-radio-group>
            </v-col>

            <v-col class="d-flex" cols="12" sm="3">
              <v-radio-group v-model="deathData.cough_reflex">
                <p>Reflexo de Tosse:</p>

                <v-radio label="Sim" 
                value="1" 
                color="#086318"
                ></v-radio>

                <v-radio label="Não" 
                value="2" 
                color="#086318"
                ></v-radio>
              </v-radio-group>
            </v-col>

            <v-col class="d-flex" cols="12" sm="3">
              <v-radio-group v-model="deathData.reflex_respiratory_drive">
                <p>Reflexo Drive Respiratório:</p>

                <v-radio label="Sim" 
                value="1" 
                color="#086318"
                ></v-radio>

                <v-radio label="Não" 
                value="2" 
                color="#086318"
                ></v-radio>
              </v-radio-group>
            </v-col>
            
            <v-col class="d-flex" cols="12" sm="3">
              <v-radio-group v-model="deathData.sedated">
                <p>Sedado:</p>

                <v-radio label="Sim" 
                value="1" 
                color="#086318"
                ></v-radio>

                <v-radio label="Não" 
                value="2" 
                color="#086318"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row v-if="this.deathData.sedated == 1">
            <v-col class="d-flex" cols="12" sm="12">
              <v-textarea 
              label="Descrição"
              rows="1"
              v-model="deathData.description"
              outlined></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field 
              color="#086318" 
              :rules="formRules" 
              label="Data de Interrupção de sedação"
              type="date" 
              outlined 
              v-model="deathData.sedation_interruption_date"
              dense></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="12" sm="6">
              <v-text-field
                color="#086318" 
                maxlength="5" 
                v-mask="'##:##'"
                label="Hora de Interrupção de sedação" 
                placeholder="Ex: 22:38" 
                outlined
                v-model="deathData.sedation_interruption_hour" 
                dense>
              </v-text-field>
            </v-col>
          </v-row>
          </div>
        
          <v-card-actions>
            <v-col class="d-flex" cols="12" sm="6">
              <v-btn style="background-color:#737373; color: white;" block @click="closeModal()">Cancelar</v-btn>
            </v-col>
            <v-col class="d-flex" cols="12" sm="6">
              <v-btn style="background-color:#50c78f; color: white;" block @click="sendData()">Salvar</v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar background-color="white" color="#2fa86d" v-model="snackbar">
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                Fechar
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import axios from "@/axios";
  export default {
    data () {
      return {
        dialog: false,
        deathData: {
          donor_id: "5",
          user_id: "1"
        },
        snackbar: false,
        message:"",
        formRules: [
          (v) => !!v || "Este campo é obrigatório",
        ],
      }
    },

    props:{
      activeModal: Boolean
    },

    methods: {
      closeModal(){
        this.$emit('fechar');
      },

      sendData(){
        const data = this.deathData
        axios
          .post(`/death_data`, data)
              .then((response) => {
                  this.message = response.data.message
                  this.snackbar = true;
                  this.dialog = false;
              }) 
          .catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
                error = value;
            });
                this.displayError.message = error.toString();
                this.displayError.display = true;
            setTimeout(() => {
                this.displayError.display = false;
            }, 3000);
        })
      }
    }
  }
</script>

<style scoped>

.form{
  padding: 30px;
}

</style>