<template>
    <div>
        <v-card-text>
            <v-form @submit.prevent="createSerology">
                <v-container>
                    <p style="font-weight:bold" v-if="((this.$store.state.profile_id == 5))">Técnico de Hemoba:</p>
                    <v-row v-if="((this.$store.state.profile_id == 5))">
                        <v-col class="d-flex" cols="12" sm="3">
                            <v-select 
                            :rules="formRules"
                            :items="hemoba_profiles"
                            color="#256936" 
                            v-model="serologyForm.hemoba_profiles_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="Tipo de Perfil">
                            </v-select>
                        </v-col>
                    </v-row>
                    
                    <p style="font-weight:bold">Doador:</p>
                    <v-row>
                        <!-- <v-col class="d-flex" cols="12" sm="3">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.hemoba_profiles_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="Tipo de Perfil">
                            </v-select>
                        </v-col> -->

                        <v-col class="d-flex" cols="12" sm="3" v-if="this.$store.state.profile_id == 5">
                            <v-autocomplete
                                :rules="formRules"
                                :items="donor"
                                color="#256936" 
                                v-model="serologyForm.donor_id"
                                item-value="id"
                                item-text="name"  
                                dense 
                                outlined 
                                label="Paciente/Provável Doador">
                            </v-autocomplete>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="3">
                            <v-text-field 
                            color="#256936" 
                            :rules="formRules" 
                            label="Registro"
                            outlined
                            v-model="serologyForm.register" 
                            maxlength="14" 
                            v-mask="'##############'"
                            dense></v-text-field>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="3">
                            <v-text-field 
                            color="#256936" 
                            :rules="formRules" 
                            label="Nº do Laudo/AAAA"
                            outlined
                            v-model="serologyForm.report_number" 
                            dense></v-text-field>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="3">
                            <v-text-field 
                            color="#086318" 
                            :rules="formRules" 
                            label="Data da Coleta de Amostra"
                            type="date" 
                            outlined 
                            v-model="serologyForm.sample_date"
                            dense></v-text-field>
                        </v-col>
                    </v-row>
                    
                    <p style="font-weight:bold">Tipo de Exame:</p>
                    <v-row style="padding-left:10px">
                        <v-radio-group :rules="formRules" v-model="serologyForm.type_report_id">
                            <v-radio label="ME" 
                            value="1" 
                            color="#086318"
                            ></v-radio>

                            <v-radio label="PCR" 
                            value="2" 
                            color="#086318"
                            ></v-radio>
                        </v-radio-group>
                    </v-row>
                    <br>
                    <p style="font-weight:bold">Resultado dos Exames Laboratoriais:</p>
                        <p>Grupo Sanguíneo:</p>
                    <v-row>
                        <v-col class="d-flex" cols="12" sm="3">
                            <v-text-field 
                            color="#256936" 
                            :rules="formRules" 
                            label="ABO"
                            outlined
                            v-model="serologyForm.abo" 
                            dense></v-text-field>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="3">
                            <v-text-field 
                            color="#256936" 
                            :rules="formRules" 
                            label="Rh"
                            outlined
                            v-model="serologyForm.rh" 
                            dense></v-text-field>
                        </v-col>
                    </v-row>

                    <p class="subtitle">Sorologia:</p>

                    <v-row>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.sifilis_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="Sífilis">
                            </v-select>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="4">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.chagas_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="Chagas">
                            </v-select>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="4">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.aghbs_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="AgHBs">
                            </v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.anti_hbc_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="ANTI-HBc">
                            </v-select>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="4">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.hcv1_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="HCV (1ª Metodologia)">
                            </v-select>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="4">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.hcv2_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="HCV (2ª Metodologia)">
                            </v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.hcv_nat_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="HCV NAT">
                            </v-select>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="4">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.anti_hbs_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="ANNTI-HBs">
                            </v-select>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="4">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.htlv_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="HTLV">
                            </v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.hiv1_2_1_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="HIV1+2 (1ª Metodologia)">
                            </v-select>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="4">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.hiv1_2_2_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="HIV1+2 (2ª Metodologia)">
                            </v-select>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="4">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.hiv_nat_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="HIV NAT">
                            </v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.t_plasmosis_lgg_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="Toxoplasmose lgG">
                            </v-select>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="4">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.t_plasmosis_lgm_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="Toxoplasmose lgM">
                            </v-select>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="4">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.cmv_lgg_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="CMV lgG">
                            </v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="d-flex" cols="12" sm="3">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.cmv_lgm_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="CMV lgM">
                            </v-select>
                        </v-col>
                    </v-row>

                    <p style="font-weight:bold">Anexos:</p>
                    <v-row>
                        <v-col class="d-flex" cols="12" sm="5">
                            <v-file-input
                                v-model="serologyAttachments.attachment"
                                label="Documento em PDF"
                                class="borderRadius10"
                                color="black"
                                append-icon="mdi-file"
                                prepend-icon=""
                                show-size
                                outlined
                                dense>
                            </v-file-input>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-text-field
                            color="#086318" 
                            label="Descrição" 
                            :rules="formRules" 
                            outlined
                            v-model="serologyAttachments.attachment_name" 
                            dense></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="3">
                            <v-tooltip right color="green">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="mx-1" small fab dark color="green" v-bind="attrs" v-on="on" @click="addAttachment()">
                                        <v-icon dark> mdi-plus </v-icon>
                                    </v-btn>
                                </template>
                            <span style="color:white">Inserir Anexo</span>
                            </v-tooltip>
                            <p class="space"></p>
                        </v-col>
                    </v-row>

                    <template>
                        <v-data-table
                            :headers="headers"
                            :items="arquivos"
                            :items-per-page="5"
                            class="elevation-1">
                            <template v-slot:item.actions="{ item }">
                                <v-icon 
                                size="20"
                                class="icon"
                                color="red" 
                                align-item="center"
                                @click="deleteAttachment(item)">
                                mdi-trash-can-outline
                                </v-icon>
                            </template>
                        </v-data-table>
                    </template>
                    
                    <p class="space-personalized"></p>
                    <div class="buttons">
                        <v-row>
                            <v-btn class="rounded-lg" elevation="0" type="submit" :disabled="disableButton" @click="createSerology()" color="green">
                            <span class="textBtn"> Cadastrar Sorologia</span>
                            </v-btn>
                            <!-- <p class="space-personalized"></p>
                            <v-btn class="rounded-lg" elevation="0" color="grey"  @click="$router.push('/donor')">
                                <span class="textBtn">sair</span>
                            </v-btn> -->
                        </v-row>
                    </div>
                </v-container>
            </v-form>
        </v-card-text>
        <v-snackbar absolute background-color="white" color="green" v-model="snackbar">
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                Fechar
                </v-btn>
            </template>
        </v-snackbar>

         <!-- Dialog de Erro -->
         <v-dialog persistent width="auto" v-model="dialogErrorSolution">
                <v-card class="deathDialog">
                    <v-card-title class="text-error-h5">
                        <v-icon dark class="donorIcon">mdi-alert</v-icon>
                        {{ message }}
                    </v-card-title>
                    <h4>Já existe uma sorologia cadastrada para este paciente.</h4>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="#ec544e"
                            variant="text"
                            value="Sim"
                            @click="$router.push('/sorologia')"
                            style="color:white"
                        >
                            Ok
                        </v-btn>
                        </v-card-actions>
                </v-card>
            </v-dialog> 
    </div>
</template>
  
<script>
import axios from "@/axios";

export default {
    data() {
        return {
            dialog: false,
            displayError: {
                display: false,
                message: [],
            },
            serologyForm: {
                user_id:""
            },
            serologyAttachments:{
                attachments_type_id: 2
            },
            headers: [
                {
                    text: "Descrição",
                    align: "center",
                    value: "attachment_name",
                },
                { 
                    text: "Ações", 
                    value: "actions", 
                    align: "center",
                    sortable: false 
                }
            ],
            tab: 2,
            donor_id: "",
            attachment: "",
            attachment_name: "",
            loading: true,
            options: {},
            donor: [],
            arquivos: [],
            status_serology: [],
            hemoba_profiles: [],
            disableButton: false,
            message: "",
            snackbar: false,
            messageError: "",
            unity: "",
            formRules: [
             (v) => !!v || "Este campo é obrigatório",
            ],
            dialogErrorSolution: false,
        };
    },

    props: {
        glasgowId: {
            type: Number
        },
    },

    created() {
        this.donorID = localStorage.getItem('donorId')
        this.glasgowId ? this.donorID = this.glasgowId : this.donorID
        axios.get(`/serology_status`).then((response) => {this.status_serology = response.data.data;});
        axios.get(`/hemoba_profiles`).then((response) => {this.hemoba_profiles = response.data.data;});
    },

    watch: {
        options: {
            handler() {
                this.indoDataTable();
            },
        },
        deep: true,
    },

    methods: {
        indoDataTable() {
            this.loading = true;
            //const id = this.$store.state.unity_id
            axios.get(`/donor`).then((response) => {
                this.loading = false;
                this.donor = response.data.data
                this.donor = this.donor.filter((x) => x.is_donor != null); //quem realmente já é doador, aceite da família
            })
        },

        createSerology() {
                if(this.$store.state.profile_id != 5){
                    this.serologyForm.donor_id = this.donorID
                }
                this.serologyForm.user_id = this.$store.state.authUser.id
                const serology = (this.serologyForm);
                axios
                    .post(`/serology/create`, serology)
                    .then((response) => {
                        if(response){
                            this.indoDataTable();
                            this.message = response.data.message;
                            this.snackbar = true;
                            this.dialog = false;
                            this.disableButton = true;
                                if(this.$store.state.profile_id != 5){
                                    this.$router.push('/busca-ativa')
                                }else{
                                    this.$router.push('/sorologia')
                                }
                        }else{
                            //this.dialogErrorSolution = true
                            this.message = "Erro"
                        }
                    })
                    .catch((err) => {
                    err;
                    var error = "";
                    Object.values(this.$store.state.errorMessage).map((value) => {
                        error = value;
                    });
                        this.displayError.message = error.toString();
                        this.displayError.display = true;
                    setTimeout(() => {
                        this.displayError.display = false;
                    }, 3000);
                });
        },
        
        loadTable(){
            axios.get("/attachments").then((response) => {
                this.arquivos = response.data.data;
                this.arquivos = this.arquivos.filter((x) => x.donor_id == this.donorID);
                this.arquivos = this.arquivos.filter((x) => x.attachments_type_id == 2);
            });
        },

        deleteAttachment(item){
        const attachmentId = item.id
            axios.delete(`/attachments/${attachmentId}`).then((response) => {
                this.loading = true;
                this.message = response.data.message;
                this.snackbar = true;
                this.loadTable();
            });
        },

        addAttachment(){
            this.serologyForm.donor_id = this.donorID
            let anexosData = new FormData();
            anexosData.append("donor_id", this.serologyForm.donor_id)
            anexosData.append("attachment_name", this.serologyAttachments.attachment_name)
            anexosData.append("attachment", this.serologyAttachments.attachment)
            anexosData.append("attachments_type_id", this.serologyAttachments.attachments_type_id)
                axios.post(`/attachments/create`, anexosData)
                        .then((response) => {
                            this.message = response.data.message;
                            this.snackbar = true;
                            this.dialog = false;
                            this.loadTable();
                            this.serologyAttachments.attachment_name = "";
                            this.serologyAttachments.attachment = "";
                        })
                        .catch((err) => {
                            err;
                            this.messageError = "Insira um arquivo PDF.";
                            this.snackbar_error = true;
                            this.dialog = false;
                            var error = "";
                            Object.values(this.$store.state.errorMessage).map((value) => {
                                error = value;
                            });
                            this.displayError.message = error.toString();
                            this.displayError.display = true;
                            setTimeout(() => {
                                this.displayError.display = false;
                            }, 3000);
                        }).finally(() => {
                    });
        }
    },

    mounted() {
        this.indoDataTable();
    },

};
</script>
  
<style scoped>

.text-error-h5{
    background-color: rgb(236, 84, 78);
    color: white;
    text-shadow: 1px 2px 2px hsl(1, 61%, 26%)
}

.deathDialog>h4{
    color: #413f3f;
    padding: 20px;
}
.header-table {
    display: flex;
    margin-bottom: 20px;
}

p{
    font-size: 16px;
    /* font-weight: bold; */
}
.d-flex {
    padding: 0 12px;
}

.space {
    width: 5px;
}

.space-personalized {
    padding: 4px;
}

.spacediferent {
    width: 7px;
}

.v-btn:not(.v-btn--round).v-size--default {
    height: 40px !important;
}

.textBtn {
    font-family: "Poppins";
    color: #ffffff;
}

.create-user-dialog {
    padding: 20px;
}

.card {
    padding: 35px;
}

.banner {
    background: #b3ffab;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right,
            #27ae60,
            #1abc9c);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right,
            #ee106d,
            #e46987);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    border-radius: 8px;

    margin-bottom: 20px;

    display: flex;
    align-items: center;
}

.icon {}

.banner>h4 {
    color: #ffffff;
    padding: 10px;
}

 .cardFooter {
    padding: 0 !important;
}
</style>
  